import Category from "./Category";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay"; // Import Swiper styles for autoplay
import { FreeMode, Pagination, Autoplay } from "swiper/modules"; // Import the Autoplay module


const Categories = ({data}) => {
  return (
    <section className="flex flex-col justify-center max-w-7xl min-h-screen px-4 py-6 mx-auto sm:px-8">
      <div className="relative  mx-auto mb-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white  text-3xl  px-4 font-bold leading-6 text-gray-900">{data?.[1].section2_heading} </span>
      </div>
    </div>
      

      <div className="w-full flex">
        <Swiper
          slidesPerView={2}
          freeMode={true}
          autoplay={{
            delay: 2500, // Time between slides in milliseconds
            disableOnInteraction: false, // Autoplay will not be disabled after user interactions
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
          }}
          modules={[FreeMode, Pagination, Autoplay]} // Add Autoplay to the modules array
          className="mySwiper m-auto border-black"
        >
          {data?.[1].categories.map((item, index) => (
            <SwiperSlide key={index}>
              <Category category={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Categories;
