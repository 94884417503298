import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const OurStateSection = () => {
  return (
    <>

    {/* <div className="bg-gray-900 py-24 mt-10 border-b-2 border border-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl tracking-tight text-white  lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block mb-2 text-2xl font-bold sm:text-4xl ">Let's Build Something Great Together
          </h2>
          <p className="inline font-sm">Whether you are a new business needing your first website or a big company wanting to improve your online presence, our FullStack Development services can help you. Contact us today to talk about your project and see how we can help you succeed.
          </p>
        </div>
        <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Subscribe
            </button>
          </div>
          <p className="mt-4 text-sm leading-6 text-gray-300">
            We care about your data. Read our{' '}
            <Link to="#" className="font-semibold text-white">
              privacy&nbsp;policy
            </Link>
            .
          </p>
        </form>
      </div>
    </div> */}
 <div className=" max-w-7xl mx-auto p-6">
<aside className=" bg-white border border-gray-200 rounded-lg shadow-md sm:p-6  dark:bg-gray-800 dark:border-gray-700" aria-label="Subscribe to the Flowbite newsletter">
  <h3 className="mb-3 text-xl text-center font-medium text-gray-900 dark:text-white">Let's Build Something Great Together</h3>
  <p className="mb-5 max-w-4xl mx-auto text-sm font-medium text-center text-gray-500 dark:text-gray-300">
  Whether you are a new business needing your first website or a big company wanting to improve your online presence, our FullStack Development services can help you. Contact us today to talk about your project and see how we can help you succeed.
  </p>
  <form>
    <div data-style="clean" className="flex justify-center mb-3">
      <div data-element="fields" data-stacked="false" className="flex items-center w-full max-w-md mb-3 seva-fields formkit-fields">
        <div className="relative w-full mr-3 formkit-field">
          <label htmlFor="member_email" className="hidden block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</label>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </div>
          <input id="member_email" className="formkit-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="email_address" aria-label="Email Address" placeholder="Your email address..." required type="email" />
        </div>
        <span className="px-5 py-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Subscribe</span>
      </div>
    </div>
  </form>
  <div className="text-sm font-medium text-gray-500 dark:text-gray-300 text-center">We care about your data. Read our <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">Terms of Service</a> and
    <a className="text-blue-600 hover:underline dark:text-blue-500" href="#"> Privacy
      Policy
    </a>.
  </div>
</aside>
</div>

    </>
  );
};

export default OurStateSection;
