// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, FreeMode, Pagination } from "swiper/modules"; // Import modules correctly
// import { Link } from "react-router-dom";
// import "swiper/css"; // Core Swiper styles
// import "swiper/css/free-mode"; // FreeMode styles
// import "swiper/css/pagination"; // Pagination styles
// import "swiper/css/autoplay"; // Autoplay styles

// const Trusted = () => {
//   const images = [
//     { title: "Way4Franchise" ,img: "/way4.png", href: "https://way4franchise.com/" },
//     {  title: "BotPay" , img: "/botpay.png", href: "https://botpay.live/" },
//     {  title: "BotPay" , img: "/botpay.png", href: "https://botpay.live/" },
//     {  title: "BotPay" , img: "/botpay.png", href: "https://botpay.live/" },

//   ];

//   return (
//     <section className="flex flex-col justify-center max-w-7xl  px-4 py-6 mx-auto sm:px-6">
//       <div className="relative mx-auto mb-10">
//         <div className="relative ">
//           <div className="lg:max-w-2xl lg:mx-auto lg:text-center ">
//             <span className="lg:text-white  text-sm text-indigo-500  lg:bg-indigo-500 px-4 py-1 rounded-full">Our Projects</span>
//           <h2 className="bg-white sm:py-3 py-1 lg:text-3xl text-2xl px-4 font-bold leading-7 text-gray-900">
//           Innovative Solutions, Proven Results
//           </h2>
//           <p className="text-sm px-4 lg:text-center text-justify">We have successfully provided IT solutions for a variety of clients, from developing software to upgrading network systems. Our work shows our ability to solve tough technology problems in different industries.</p>
//           </div>
//         </div>
//       </div>

//       <div className="w-full">
//   <Swiper
//     slidesPerView={3}
//     freeMode={true}
//     autoplay={{
//       delay: 2500,
//       disableOnInteraction: false,
//     }}
//     pagination={{
//       clickable: true,
//     }}
//     spaceBetween={12} // Set spacing between slides (equivalent to gap-3)
//     breakpoints={{
//       240: {
//         slidesPerView: 1,
//         spaceBetween: 20,
//       },
//       640: {
//         slidesPerView: 1,
//         spaceBetween: 20,
//       },
//       768: {
//         slidesPerView: 2,
//         spaceBetween: 20,
//       },
//       1024: {
//         slidesPerView: 3,
//         spaceBetween: 12, // Apply spaceBetween here
//       },
//     }}
//     modules={[FreeMode, Pagination, Autoplay]}
//     className="mySwiper m-auto border-black"
//   >
//     {images?.map((item, index) => (
//       <SwiperSlide key={index} className="w-full">
//         <div className="shadow-lg border border-gray-200 px-6 py-8 rounded-lg transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl bg-gradient-to-br from-white to-gray-100">
//           <Link to={item.href} target="_blank" rel="noopener noreferrer">
//             <img
//               src={item.img}
//               alt={`Slide ${index + 1}`}
//               className="object-cover object-center w-full h-48 rounded-lg transition-opacity duration-300 hover:opacity-90"
//             />
//             <div className="mt-4 text-center">
//               <h3 className="text-base font-semibold text-gray-800">
//                 {item.title}
//               </h3>
//               <Link
//                 to={item.href}
//                 className="text-sm hover:underline hover:text-indigo-600 text-indigo-500 font-semibold capitalize"
//               >
//                 Click here
//               </Link>
//             </div>
//           </Link>
//         </div>
//       </SwiperSlide>
//     ))}
//   </Swiper>
// </div>

//     </section>
//   );
// };

// export default Trusted;

import React, { useState } from "react";
import { Link } from "react-router-dom";
export const Trusted = () => {
  const [activeTab, setActiveTab] = useState("project");

  // Handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const product = [
    {
      id: 1,
      name: "M S Montessori School",
      description:
        "Dedicated to the overall growth of children, this school's website shows its commitment to inspiring curiosity and creativity in every student.",
      image: "./ms.png",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },

    {
      id: 2,
      name: "Hospital CRM",
      description:
        "Improve patient services and organize hospital tasks with our simple-to-use Hospital CRM. It also helps track appointments and medical records efficiently.",
      image: "./dr.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 3,
      name: "MLM CRM",
      description:
        "Handle your multi-level marketing business efficiently with our powerful CRM solution. It also provides tools for tracking sales and managing team networks.",
      image: "./mlm.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 4,
      name: "Games System",
      description:
        "We create fun and interactive games designed to entertain people of all ages. Our games also focus on improving creativity and problem-solving skills.",
      image: "./game.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 5,
      name: "Learning Management System",
      description:
        "Make online learning simple with our system for creating and managing courses. It also supports tracking progress and providing certifications.",
      image: "./lms.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 6,
      name: "ERP",
      description:
        "Run your business smoothly with our system that connects and improves all your processes. It also ensures better data management and faster decision-making.",
      image: "./erp.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 7,
      name: "Invoice Billing Software",
      description:
        "Create and manage invoices without any hassle using our easy billing software. It also helps with tracking payments and generating detailed reports.",
      image: "./inv.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
    {
      id: 8,
      name: "School CRM",
      description:
        "Manage school activities easily with our system for student records, attendance, and communication. It also helps streamline scheduling and fee management.",
      image: "./crm.jpg",
      // href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
  ];

  const project = [
    {
      id: 1,
      name: "Way4 Franchise",
      description:
        "Find and explore franchise opportunities easily on our website. It also helps you manage inquiries and applications smoothly.",
      image: "https://way4franchise.com/logo.png",
      href: "https://way4franchise.com/",
      bgcolor: "bg-black",
    },

    {
      id: 2,
      name: "Guru Nanak Khalsa Sr. Secondary School",
      description:
        "Helping young minds grow with a mix of traditional and modern education. The school's website highlights their focus on academic success and strong cultural",
      image: "/gnk.png",
      bgcolor: "bg-black",
      href: "https://gnkschool.info/home/",
    },
    {
      id: 3,
      name: "The Music Verse",
      description:
        "The Music Verse is a collection that celebrates the popular beats and melodies of Punjabi music, blending them with digital art for a unique experience.",
      image: "/babu.webp",
      href: "https://opensea.io/collection/the-music-verse-1",
      bgcolor: "bg-gradient-to-r from-[#409380] to-[#66bb6a]",
    },
    {
      id: 4,
      name: "The Black Panther Versa",
      description:
        "The Black Panther Versa is an exciting NFT collection that combines art and technology to celebrate the power and strength of the Black Panther.",
      image: "/black.avif",
      href: "https://opensea.io/collection/the-black-panther-versa",
      bgcolor: "bg-[#5c2118]",
    },
    {
      id: 5,
      name: "Own Basket",
      description:
        "Own Basket offers a great range of quality products, making online shopping easy for all your needs.",
      image: "/own.png",
      href: "https://ownbasket.shop/",
      bgcolor: "bg-white",
    },
    {
      id: 6,
      name: "Ganesh Car",
      description:
        "Ganesh Cars offers a wide range of quality pre-owned vehicles, providing customers with a seamless car buying experience and exceptional service.",
      image: "./ganesh.png",
      href: "https://www.ganeshcars.com/",
      bgcolor: "bg-black",
    },
    {
      id: 7,
      name: "Tradehit",
      description:
        "Trade Hit is a trusted crypto exchange platform offering secure and efficient trading solutions, catering to both novice and experienced traders.",
      image: "./tradehit.png",
      href: "https://tradehit.io/",
      bgcolor: "bg-black",
    },
    {
      id: 7,
      name: "Jastrobot",
      description:
        "JastroFX is a platform that combines expert trading strategies and automated bots to help users learn, trade, and earn in the financial markets with ease.",
      image: "./bfc.png",
      href: "https://jastrobot.com/",
      bgcolor: "bg-black",
    },
    {
      id: 8,
      name: "Mgennx",
      description:
        "Ganesh Cars offers a wide range of quality pre-owned vehicles, providing customers with a seamless car buying experience and exceptional service.",
      image: "./mg.png",
      href: "https://mgennx.com/",
      bgcolor: "bg-black",
    },
    {
      id: 9,
      name: "M S Montessori School",
      description:
        "Dedicated to the overall growth of children, this school's website shows its commitment to inspiring curiosity and creativity in every student.",
      image: "./ms.png",
      href: "https://montessorikkr.com/",
      bgcolor: "bg-white",
    },
  ];

  return (
    <section className="bg-white ">
      <div className="max-w-7xl px-6 py-10 mx-auto">
        <h1 className="text-2xl font-bold text-center text-gray-800 capitalize lg:text-3xl ">
          Innovative Projects and Products
        </h1>
        <p className="max-w-2xl mx-auto mt-2 mb-4 text-sm text-center text-gray-500 ">
          Our projects and products are designed to meet the needs of different
          industries, helping businesses grow and work more efficiently. We
          offer easy-to-use tools like CRM systems, ERP, and LMS to help
          companies succeed.
        </p>
        <div className="flex items-center justify-center">
          <div className="relative flex items-center p-1 border border-gray-300 shadow-md bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl">
            <span
              className={`absolute top-1 bottom-1 left-1 w-[calc(50%-0.3rem)] bg-gradient-to-r from-indigo-400 to-blue-400 rounded-lg shadow-md transition-all duration-300 ease-in-out ${
                activeTab === "product" ? "translate-x-full" : "translate-x-0"
              }`}
            ></span>
            <button
              className={`relative z-10 px-4 py-2 text-sm font-medium capitalize md:py-3 md:px-12 rounded-lg transition-all duration-300 ease-in-out ${
                activeTab === "project"
                  ? "text-white"
                  : "text-gray-700 hover:text-indigo-600"
              }`}
              onClick={() => handleTabClick("project")}
            >
              Projects
            </button>
            <button
              className={`relative z-10 px-4 py-2 text-sm font-medium capitalize md:py-3 md:px-12 rounded-lg transition-all duration-300 ease-in-out ${
                activeTab === "product"
                  ? "text-white"
                  : "text-gray-700 hover:text-indigo-600"
              }`}
              onClick={() => handleTabClick("product")}
            >
              Product
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-8 xl:mt-16 sm:grid-cols-2 xl:grid-cols-4">
          {activeTab === "project" && (
            <>
              {project.map((data, index) => (
                <Link to={data.href}>
                  <div
                    key={index}
                    className="flex flex-col bg-white shadow-sm hover:shadow-xl items-center p-8 h-full transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-500 "
                  >
                    <div
                      className={`w-32 h-32 rounded-full ring-4 ring-gray-300 flex justify-center items-center p-4 ${data.bgcolor}`}
                    >
                      <img className="rounded-full" src={data.image} alt />
                    </div>
                    <h1 className="mt-4 text-sm text-center font-semibold text-gray-800 capitalize  group-hover:text-white">
                      {data.name}
                    </h1>
                    <p className=" text-gray-700 mt-1 text-[12px] text-justify capitalize  group-hover:text-gray-100">
                      {data.description}
                    </p>
                  </div>
                </Link>
              ))}
            </>
          )}
          {activeTab === "product" && (
            <>
              {product.map((data, index) => (
                <Link key={index} to={data.href}>
                  <div
                    key={index}
                    className="flex flex-col bg-white shadow-sm hover:shadow-xl items-center p-8 h-full transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-500 "
                  >
                    <div
                      className={`w-32 h-32 rounded-full ring-4 ring-gray-300 flex justify-center items-center p-4 ${data.bgcolor}`}
                    >
                      <img className="rounded-full" src={data.image} alt />
                    </div>
                    <h1 className="mt-4 text-sm text-center font-semibold text-gray-800 capitalize  group-hover:text-white">
                      {data.name}
                    </h1>
                    <p className=" text-gray-700 mt-1 text-[12px] text-justify capitalize  group-hover:text-gray-100">
                      {data.description}
                    </p>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
